.input-fields,
textarea {
    height: 40px;
    border: 1px solid #0F3A5754;
    border-radius: 4px;
    -webkit-box-shadow: inset 2px 2px 6px #0000001A;
    -moz-box-shadow: inset 2px 2px 6px #0000001A;
    box-shadow: inset 2px 2px 6px #0000001A;

    background: white;
    color: #49BAF1;
    padding-left: 1.5rem;
    font-weight: 400;
    font-size: 16px;
}

.input-fields:focus,
textarea:focus {
    border: 1px solid #49BAF1;
    outline: none;
}

textarea {
    resize: none !important;
    width: 100%;
    padding-top: 1rem;
    padding-left: 1.5rem;
    height: auto;
}

#form {
    overflow-y: auto !important;
    position: fixed;
    width: 100vw;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    z-index: 105;
}

@media only screen and (max-width: 575px) {
    .form-submitted {
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
    }

    .form-submitted-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 14.5vw;
        padding: 0 6vw;
    }

    .form-submitted-content h1 {
        margin-top: 2rem;
        font-size: 24px;
        color: #34790F;
        text-align: center;
    }

    .form-container {
        padding: 6vw 6vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .close {
        position: fixed;
        bottom: 1rem;
        right: 1rem;
        cursor: pointer;
        width: 40px;
    }

    .form-content {
        display: flex;
        flex-direction: column;
    }

    .form-separator {
        width: 140px;
        height: 1px;
        background: #0F3A574D;
        margin-top: 2.5rem;
        margin-bottom: 0.75rem;
    }

    .form-content h1 {
        font-size: 36px;
        color: #0F3A57;
        margin-top: 2rem;
        margin-bottom: 1.75rem;
    }

    .form-content h5 {
        font-size: 19px;
        color: #0F3A57B3;
        width: 100%;
    }

    .form-content p {
        font-size: 13px;
        color: #0F3A57B3;
        width: 70%;
    }

    .form-fields {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 3rem;
    }

    .row1 {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
    }

    .row2 {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
    }

    .row3 {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        padding-left: 2rem;
        position: relative;
    }

    .row1-column1 {
        display: flex;
        flex-direction: column;
        margin-bottom:2rem;
    }

    .row1-column2 {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .row2-column1 {
        display: flex;
        flex-direction: column;
        margin-bottom:2rem;
    }

    .row2-column2 {
        display: flex;
        flex-direction: column;
    }

    .full-name-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .full-name-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }

    .full-name-field {
        padding-left: 30px;
        width: 100%;
    }

    .full-name-field input {
        width: 100%;
    }

    .phone-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .phone-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }


    .phone-field {
        padding-left: 30px;
    }

    .phone-field input {
        width: 100%;
    }

    .email-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .email-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }

    .email-field {
        padding-left: 30px;
        width: 100%;
    }

    .email-field input {
        width: 100%;
    }

    .check {
        width: 20px;
    }

    .check img {
        width: 20px;
    }

    .input-fields {
        height: 40px;
        border: 1px solid #0F3A5754;
        border-radius: 4px;
        -webkit-box-shadow: inset 2px 2px 6px #0000001A;
        -moz-box-shadow: inset 2px 2px 6px #0000001A;
        box-shadow: inset 2px 2px 6px #0000001A;

        background: white;
        color: #49BAF1;
        padding-left: 1.5rem;
    }

    .input-fields:focus {
        border: 1px solid #49BAF1;
        outline: none;
    }

    .calendly {
        width: 170px;
        padding-left: 30px;
    }

    .schedule {
        display: flex;
        align-items: center;
        padding-left: 30px;
        margin-top: 0.5rem;
    }

    .schedule a {
        margin-right: 0.5rem;
        font-size: 14px;
    }

    .submit-btn {
        position: absolute;
        right: 20px;
        bottom: 20px;
    }

    .submit-btn button {
        padding: 16px 55px 18px 55px;
        background: #49BAF1;
        color: white;
        border: 1px solid #49BAF1;
        border-radius: 88px;
        font-size: 16px;
    }
}

@media only screen and (min-width: 576px) and (max-width:767px) {
    .form-submitted {
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
    }

    .form-submitted-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 14.5vw;
        padding: 0 6vw;
    }

    .form-submitted-content h1 {
        margin-top: 2rem;
        font-size: 36px;
        color: #34790F;
        text-align: center;
    }

    .form-container {
        padding: 6vw 6vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .close {
        position: fixed;
        bottom: 1rem;
        right: 1rem;
        cursor: pointer;
        width: 40px;
    }

    .form-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 3vw;
        width: 492px;
    }

    .form-separator {
        width: 140px;
        height: 1px;
        background: #0F3A574D;
        margin-top: 2.5rem;
        margin-bottom: 0.75rem;
    }

    .form-content h1 {
        font-size: 36px;
        color: #0F3A57;
        margin-top: 2rem;
        margin-bottom: 1.75rem;
    }

    .form-content h5 {
        font-size: 19px;
        color: #0F3A57B3;
        width: 86%;
    }

    .form-content p {
        font-size: 13px;
        color: #0F3A57B3;
        width: 70%;
    }

    .form-fields {
        display: flex;
        flex-direction: column;
        width: 500px;
        margin-top: 3rem;
    }

    .row1 {
        display: flex;
        margin-bottom: 2rem;
    }

    .row2 {
        display: flex;
        margin-bottom: 2rem;
    }

    .row3 {
        display: flex;
        margin-bottom: 2rem;
        padding-left: 2rem;
        position: relative;
    }

    .row1-column1 {
        display: flex;
        flex-direction: column;
        margin-right: 0.5rem;
    }

    .row1-column2 {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .row2-column1 {
        display: flex;
        flex-direction: column;
        margin-right: 0.5rem;
    }

    .row2-column2 {
        display: flex;
        flex-direction: column;
    }

    .full-name-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .full-name-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }

    .full-name-field {
        padding-left: 30px;
        width: 220px;
    }

    .full-name-field input {
        width: 90%;
    }

    .phone-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .phone-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }


    .phone-field {
        padding-left: 30px;
    }

    .phone-field input {
        width: 100%;
    }

    .email-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .email-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }

    .email-field {
        padding-left: 30px;
        width: 252px;
    }

    .email-field input {
        width: 100%;
    }

    .check {
        width: 20px;
    }

    .check img {
        width: 20px;
    }

    .input-fields {
        height: 40px;
        border: 1px solid #0F3A5754;
        border-radius: 4px;
        -webkit-box-shadow: inset 2px 2px 6px #0000001A;
        -moz-box-shadow: inset 2px 2px 6px #0000001A;
        box-shadow: inset 2px 2px 6px #0000001A;

        background: white;
        color: #49BAF1;
        padding-left: 1.5rem;
    }

    .input-fields:focus {
        border: 1px solid #49BAF1;
        outline: none;
    }

    .calendly {
        width: 170px;
        padding-left: 30px;
    }

    .schedule {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 30px;
        margin-top: 0.5rem;
    }

    .schedule a {
        margin-right: 0.5rem;
        font-size: 14px;
    }

    .submit-btn {
        position: absolute;
        right: 20px;
        bottom: 20px;
    }

    .submit-btn button {
        padding: 16px 55px 18px 55px;
        background: #49BAF1;
        color: white;
        border: 1px solid #49BAF1;
        border-radius: 88px;
        font-size: 16px;
    }
}

@media only screen and (min-width: 768px) and (max-width:991px) {

    .form-submitted {
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
    }

    .form-submitted-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 14.5vw;
        padding: 0 6vw;
    }

    .form-submitted-content h1 {
        margin-top: 2rem;
        font-size: 36px;
        color: #34790F;
        text-align: center;
    }

    .form-container {
        padding: 6vw 6vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .close {
        position: fixed;
        bottom: 1rem;
        right: 1rem;
        cursor: pointer;
        width: 40px;
    }

    .form-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 3vw;
        width: 492px;
    }

    .form-separator {
        width: 140px;
        height: 1px;
        background: #0F3A574D;
        margin-top: 2.5rem;
        margin-bottom: 0.75rem;
    }

    .form-content h1 {
        font-size: 36px;
        color: #0F3A57;
        margin-top: 2rem;
        margin-bottom: 1.75rem;
    }

    .form-content h5 {
        font-size: 19px;
        color: #0F3A57B3;
        width: 86%;
    }

    .form-content p {
        font-size: 13px;
        color: #0F3A57B3;
        width: 70%;
    }

    .form-fields {
        display: flex;
        flex-direction: column;
        width: 600px;
        margin-top: 3rem;
    }

    .row1 {
        display: flex;
        margin-bottom: 2rem;
    }

    .row2 {
        display: flex;
        margin-bottom: 2rem;
    }

    .row3 {
        display: flex;
        margin-bottom: 2rem;
        padding-left: 2rem;
        position: relative;
        padding-bottom: 60px;
    }

    .row1-column1 {
        display: flex;
        flex-direction: column;
        margin-right: 0.5rem;
    }

    .row1-column2 {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .row2-column1 {
        display: flex;
        flex-direction: column;
        margin-right: 0.5rem;
    }

    .row2-column2 {
        display: flex;
        flex-direction: column;
    }

    .full-name-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .full-name-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }

    .full-name-field {
        padding-left: 30px;
        width: 310px;
    }

    .full-name-field input {
        width: 80%;
    }

    .phone-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .phone-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }


    .phone-field {
        padding-left: 30px;
    }

    .phone-field input {
        width: 100%;
    }

    .email-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .email-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }

    .email-field {
        padding-left: 30px;
        width: 40vw;
    }

    .email-field input {
        width: 100%;
    }

    .check {
        width: 20px;
    }

    .check img {
        width: 20px;
    }

    .input-fields {
        height: 40px;
        border: 1px solid #0F3A5754;
        border-radius: 4px;
        -webkit-box-shadow: inset 2px 2px 6px #0000001A;
        -moz-box-shadow: inset 2px 2px 6px #0000001A;
        box-shadow: inset 2px 2px 6px #0000001A;

        background: white;
        color: #49BAF1;
        padding-left: 1.5rem;
    }

    .input-fields:focus {
        border: 1px solid #49BAF1;
        outline: none;
    }

    .calendly {
        width: 170px;
        padding-left: 30px;
    }

    .schedule {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 30px;
        margin-top: 0.5rem;
    }

    .schedule a {
        margin-right: 0.5rem;
        font-size: 14px;
    }

    .submit-btn {
        position: absolute;
        right: 20px;
        bottom: 80px;
    }

    .submit-btn button {
        padding: 16px 55px 18px 55px;
        background: #49BAF1;
        color: white;
        border: 1px solid #49BAF1;
        border-radius: 88px;
        font-size: 16px;
    }
}

@media only screen and (min-width: 992px) and (max-width:1199px) {
    .form-submitted {
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
    }

    .form-submitted-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 14.5vw;
        padding: 0 6vw;
    }

    .form-submitted-content h1 {
        margin-top: 2rem;
        font-size: 48px;
        color: #34790F;
        text-align: center;
    }

    .form-container {
        padding: 6vw 14.5vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .close {
        position: fixed;
        bottom: 1rem;
        right: 1rem;
        cursor: pointer;
        width: 40px;
    }

    .form-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 3vw;
        width: 492px;
    }

    .form-separator {
        width: 140px;
        height: 1px;
        background: #0F3A574D;
        margin-top: 2.5rem;
        margin-bottom: 0.75rem;
    }

    .form-content h1 {
        font-size: 36px;
        color: #0F3A57;
        margin-top: 2rem;
        margin-bottom: 1.75rem;
    }

    .form-content h5 {
        font-size: 19px;
        color: #0F3A57B3;
        width: 86%;
    }

    .form-content p {
        font-size: 13px;
        color: #0F3A57B3;
        width: 70%;
    }

    .form-fields {
        display: flex;
        flex-direction: column;
        width: 700px;
        margin-top: 3rem;
    }

    .row1 {
        display: flex;
        margin-bottom: 2rem;
    }

    .row2 {
        display: flex;
        margin-bottom: 2rem;
    }

    .row3 {
        display: flex;
        margin-bottom: 2rem;
        padding-left: 2rem;
        position: relative;
        padding-bottom: 60px;
    }

    .row1-column1 {
        display: flex;
        flex-direction: column;
        margin-right: 0.5rem;
    }

    .row1-column2 {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .row2-column1 {
        display: flex;
        flex-direction: column;
        margin-right: 0.5rem;
    }

    .row2-column2 {
        display: flex;
        flex-direction: column;
    }

    .full-name-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .full-name-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }

    .full-name-field {
        padding-left: 30px;
        width: 30vw;
    }

    .full-name-field input {
        width: 80%;
    }

    .phone-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .phone-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }


    .phone-field {
        padding-left: 30px;
    }

    .phone-field input {
        width: 100%;
    }

    .email-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .email-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }

    .email-field {
        padding-left: 30px;
        width: 30vw;
    }

    .email-field input {
        width: 100%;
    }

    .check {
        width: 20px;
    }

    .check img {
        width: 20px;
    }

    .input-fields {
        height: 40px;
        border: 1px solid #0F3A5754;
        border-radius: 4px;
        -webkit-box-shadow: inset 2px 2px 6px #0000001A;
        -moz-box-shadow: inset 2px 2px 6px #0000001A;
        box-shadow: inset 2px 2px 6px #0000001A;

        background: white;
        color: #49BAF1;
        padding-left: 1.5rem;
    }

    .input-fields:focus {
        border: 1px solid #49BAF1;
        outline: none;
    }

    .calendly {
        width: 15vw;
        padding-left: 30px;
    }

    .schedule {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 30px;
        margin-top: 0.5rem;
    }

    .schedule a {
        margin-right: 0.5rem;
        font-size: 14px;
    }

    .submit-btn {
        position: absolute;
        right: 20px;
        bottom: 80px;
    }

    .submit-btn button {
        padding: 16px 55px 18px 55px;
        background: #49BAF1;
        color: white;
        border: 1px solid #49BAF1;
        border-radius: 88px;
        font-size: 16px;
    }
}

@media only screen and (min-width: 1200px) and (max-width:1399px) {

    .form-submitted {
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
    }

    .form-submitted-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 14.5vw;
    }

    .form-submitted-content h1 {
        margin-top: 2rem;
        max-width: 50vw;
        font-size: 52px;
        color: #34790F;
        text-align: center;
    }

    .form-container {
        height: 100vh;
        width: 100vw;
        padding: 6vw 6vw;
        display: flex;
        justify-content: center;
    }

    .close {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        cursor: pointer;
        width: 40px;
    }

    .form-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 3vw;
        width: 492px;
    }

    .form-separator {
        width: 140px;
        height: 1px;
        background: #0F3A574D;
        margin-top: 2.5rem;
        margin-bottom: 0.75rem;
    }

    .form-content h1 {
        font-size: 36px;
        color: #0F3A57;
        margin-top: 2rem;
        margin-bottom: 1.75rem;
    }

    .form-content h5 {
        font-size: 19px;
        color: #0F3A57B3;
        width: 86%;
    }

    .form-content p {
        font-size: 13px;
        color: #0F3A57B3;
        width: 70%;
    }

    .form-fields {
        display: flex;
        flex-direction: column;
        width: 700px;
        margin-top: 3rem;
    }

    .row1 {
        display: flex;
        margin-bottom: 2rem;
    }

    .row2 {
        display: flex;
        margin-bottom: 2rem;
    }

    .row3 {
        display: flex;
        margin-bottom: 2rem;
        padding-left: 2rem;
        position: relative;
        padding-bottom: 60px;
    }

    .row1-column1 {
        display: flex;
        flex-direction: column;
        margin-right: 0.5rem;
    }

    .row1-column2 {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .row2-column1 {
        display: flex;
        flex-direction: column;
        margin-right: 0.5rem;
    }

    .row2-column2 {
        display: flex;
        flex-direction: column;
    }

    .full-name-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .full-name-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }

    .full-name-field {
        padding-left: 30px;
        width: 20vw;
    }

    .full-name-field input {
        width: 80%;
    }

    .phone-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .phone-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }


    .phone-field {
        padding-left: 30px;
    }

    .phone-field input {
        width: 100%;
    }

    .email-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .email-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }

    .email-field {
        padding-left: 30px;
        width: 20vw;
    }

    .email-field input {
        width: 100%;
    }

    .check {
        width: 20px;
    }

    .check img {
        width: 20px;
    }

    .input-fields {
        height: 40px;
        border: 1px solid #0F3A5754;
        border-radius: 4px;
        -webkit-box-shadow: inset 2px 2px 6px #0000001A;
        -moz-box-shadow: inset 2px 2px 6px #0000001A;
        box-shadow: inset 2px 2px 6px #0000001A;

        background: white;
        color: #49BAF1;
        padding-left: 1.5rem;
    }

    .input-fields:focus {
        border: 1px solid #49BAF1;
        outline: none;
    }

    .calendly {
        width: 10vw;
        padding-left: 30px;
    }

    .schedule {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 30px;
        margin-top: 0.5rem;
    }

    .schedule a {
        margin-right: 0.5rem;
        font-size: 14px;
    }

    .submit-btn {
        position: absolute;
        right: 20px;
        bottom: 80px;
    }

    .submit-btn button {
        padding: 16px 55px 18px 55px;
        background: #49BAF1;
        color: white;
        border: 1px solid #49BAF1;
        border-radius: 88px;
        font-size: 16px;
    }
}

@media only screen and (min-width: 1400px) and (max-width:1799px) {

    .form-submitted {
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
    }

    .form-submitted-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 14.5vw;
    }

    .form-submitted-content h1 {
        margin-top: 2rem;
        max-width: 50vw;
        font-size: 52px;
        color: #34790F;
        text-align: center;
    }

    .form-container {
        height: 100vh;
        width: 100vw;
        padding: 6vw 6vw;
        display: flex;
        justify-content: center;
    }

    .close {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        cursor: pointer;
        width: 60px;
    }

    .form-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 3vw;
        width: 492px;
    }

    .form-separator {
        width: 140px;
        height: 1px;
        background: #0F3A574D;
        margin-top: 2.5rem;
        margin-bottom: 0.75rem;
    }

    .form-content h1 {
        font-size: 48px;
        color: #0F3A57;
        margin-top: 2rem;
        margin-bottom: 1.75rem;
    }

    .form-content h5 {
        font-size: 19px;
        color: #0F3A57B3;
        width: 86%;
    }

    .form-content p {
        font-size: 13px;
        color: #0F3A57B3;
        width: 70%;
    }

    .form-fields {
        display: flex;
        flex-direction: column;
        width: 700px;
        margin-top: 3rem;
    }

    .row1 {
        display: flex;
        margin-bottom: 2rem;
    }

    .row2 {
        display: flex;
        margin-bottom: 2rem;
    }

    .row3 {
        display: flex;
        margin-bottom: 2rem;
        padding-left: 2rem;
        position: relative;
        padding-bottom: 60px;
    }

    .row1-column1 {
        display: flex;
        flex-direction: column;
        margin-right: 0.5rem;
    }

    .row1-column2 {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .row2-column1 {
        display: flex;
        flex-direction: column;
        margin-right: 0.5rem;
    }

    .row2-column2 {
        display: flex;
        flex-direction: column;
    }

    .full-name-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .full-name-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }

    .full-name-field {
        padding-left: 30px;
        width: 20vw;
    }

    .full-name-field input {
        width: 80%;
    }

    .phone-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .phone-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }


    .phone-field {
        padding-left: 30px;
    }

    .phone-field input {
        width: 100%;
    }

    .email-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .email-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }

    .email-field {
        padding-left: 30px;
        width: 20vw;
    }

    .email-field input {
        width: 100%;
    }

    .check {
        width: 20px;
    }

    .check img {
        width: 20px;
    }

    .input-fields {
        height: 40px;
        border: 1px solid #0F3A5754;
        border-radius: 4px;
        -webkit-box-shadow: inset 2px 2px 6px #0000001A;
        -moz-box-shadow: inset 2px 2px 6px #0000001A;
        box-shadow: inset 2px 2px 6px #0000001A;

        background: white;
        color: #49BAF1;
        padding-left: 1.5rem;
    }

    .input-fields:focus {
        border: 1px solid #49BAF1;
        outline: none;
    }

    .calendly {
        width: 10vw;
        padding-left: 30px;
    }

    .schedule {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 30px;
        margin-top: 0.5rem;
    }

    .schedule a {
        margin-right: 0.5rem;
        font-size: 14px;
    }

    .submit-btn {
        position: absolute;
        right: 20px;
        bottom: 80px;
    }

    .submit-btn button {
        padding: 16px 55px 18px 55px;
        background: #49BAF1;
        color: white;
        border: 1px solid #49BAF1;
        border-radius: 88px;
        font-size: 16px;
    }
}

@media only screen and (min-width: 1800px) and (max-width:2399px) {

    .form-submitted {
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
    }

    .form-submitted-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 14.5vw;
    }

    .form-submitted-content h1 {
        margin-top: 2rem;
        max-width: 50vw;
        font-size: 52px;
        color: #34790F;
        text-align: center;
    }

    .form-container {
        height: 100vh;
        width: 100vw;
        padding: 6vw 14.5vw;
        display: flex;
        justify-content: center;
    }

    .close {
        position: absolute;
        bottom: 2rem;
        right: 2rem;
        cursor: pointer;
    }

    .form-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 3vw;
        width: 492px;
    }

    .form-separator {
        width: 140px;
        height: 1px;
        background: #0F3A574D;
        margin-top: 2.5rem;
        margin-bottom: 0.75rem;
    }

    .form-content h1 {
        font-size: 52px;
        color: #0F3A57;
        margin-top: 2rem;
        margin-bottom: 1.75rem;
    }

    .form-content h5 {
        font-size: 19px;
        color: #0F3A57B3;
        width: 74%;
    }

    .form-content p {
        font-size: 13px;
        color: #0F3A57B3;
        width: 58%;
    }

    .form-fields {
        display: flex;
        flex-direction: column;
        width: 700px;
        margin-top: 3rem;
    }

    .row1 {
        display: flex;
        margin-bottom: 2rem;
    }

    .row2 {
        display: flex;
        margin-bottom: 2rem;
    }

    .row3 {
        display: flex;
        margin-bottom: 2rem;
        padding-left: 2rem;
        position: relative;
        padding-bottom: 60px;
    }

    .row1-column1 {
        display: flex;
        flex-direction: column;
        margin-right: 0.5rem;
    }

    .row1-column2 {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .row2-column1 {
        display: flex;
        flex-direction: column;
        margin-right: 0.5rem;
    }

    .row2-column2 {
        display: flex;
        flex-direction: column;
    }

    .full-name-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .full-name-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }

    .full-name-field {
        padding-left: 30px;
        width: 20vw;
    }

    .full-name-field input {
        width: 80%;
    }

    .phone-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .phone-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }


    .phone-field {
        padding-left: 30px;
    }

    .phone-field input {
        width: 100%;
    }

    .email-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .email-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }

    .email-field {
        padding-left: 30px;
        width: 20vw;
    }

    .email-field input {
        width: 100%;
    }

    .check {
        width: 20px;
    }

    .check img {
        width: 20px;
    }

    .input-fields {
        height: 40px;
        border: 1px solid #0F3A5754;
        border-radius: 4px;
        -webkit-box-shadow: inset 2px 2px 6px #0000001A;
        -moz-box-shadow: inset 2px 2px 6px #0000001A;
        box-shadow: inset 2px 2px 6px #0000001A;

        background: white;
        color: #49BAF1;
        padding-left: 1.5rem;
    }

    .input-fields:focus {
        border: 1px solid #49BAF1;
        outline: none;
    }

    .calendly {
        width: 10vw;
        padding-left: 30px;
    }

    .schedule {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 30px;
        margin-top: 0.5rem;
    }

    .schedule a {
        margin-right: 0.5rem;
        font-size: 14px;
    }

    .submit-btn {
        position: absolute;
        right: 20px;
        bottom: 80px;
    }

    .submit-btn button {
        padding: 16px 55px 18px 55px;
        background: #49BAF1;
        color: white;
        border: 1px solid #49BAF1;
        border-radius: 88px;
        font-size: 16px;
    }
}

@media only screen and (min-width: 2400px) {

    .form-submitted {
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
    }

    .form-submitted-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 14.5vw;
    }

    .form-submitted-content h1 {
        margin-top: 2rem;
        max-width: 50vw;
        font-size: 52px;
        color: #34790F;
        text-align: center;
    }

    .form-container {
        height: 100vh;
        width: 100vw;
        padding: 6vw 14.5vw;
        display: flex;
        justify-content: center;
    }

    .close {
        position: absolute;
        bottom: 2rem;
        right: 2rem;
        cursor: pointer;
    }

    .form-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 3vw;
        width: 492px;
    }

    .form-separator {
        width: 140px;
        height: 1px;
        background: #0F3A574D;
        margin-top: 2.5rem;
        margin-bottom: 0.75rem;
    }

    .form-content h1 {
        font-size: 52px;
        color: #0F3A57;
        margin-top: 2rem;
        margin-bottom: 1.75rem;
    }

    .form-content h5 {
        font-size: 19px;
        color: #0F3A57B3;
        width: 74%;
    }

    .form-content p {
        font-size: 13px;
        color: #0F3A57B3;
        width: 58%;
    }

    .form-fields {
        display: flex;
        flex-direction: column;
        width: 700px;
        margin-top: 3rem;
    }

    .row1 {
        display: flex;
        margin-bottom: 2rem;
    }

    .row2 {
        display: flex;
        margin-bottom: 2rem;
    }

    .row3 {
        display: flex;
        margin-bottom: 2rem;
        padding-left: 2rem;
        position: relative;
        padding-bottom: 60px;
    }

    .row1-column1 {
        display: flex;
        flex-direction: column;
        margin-right: 0.5rem;
    }

    .row1-column2 {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .row2-column1 {
        display: flex;
        flex-direction: column;
        margin-right: 0.5rem;
    }

    .row2-column2 {
        display: flex;
        flex-direction: column;
    }

    .full-name-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .full-name-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }

    .full-name-field {
        padding-left: 30px;
        width: 20vw;
    }

    .full-name-field input {
        width: 80%;
    }

    .phone-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .phone-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }


    .phone-field {
        padding-left: 30px;
    }

    .phone-field input {
        width: 100%;
    }

    .email-text {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .email-text p {
        margin: 0;
        padding-left: 10px;
        color: #143A57;
    }

    .email-field {
        padding-left: 30px;
        width: 20vw;
    }

    .email-field input {
        width: 100%;
    }

    .check {
        width: 20px;
    }

    .check img {
        width: 20px;
    }

    .input-fields {
        height: 40px;
        border: 1px solid #0F3A5754;
        border-radius: 4px;
        -webkit-box-shadow: inset 2px 2px 6px #0000001A;
        -moz-box-shadow: inset 2px 2px 6px #0000001A;
        box-shadow: inset 2px 2px 6px #0000001A;

        background: white;
        color: #49BAF1;
        padding-left: 1.5rem;
    }

    .input-fields:focus {
        border: 1px solid #49BAF1;
        outline: none;
    }

    .calendly {
        width: 10vw;
        padding-left: 30px;
    }

    .schedule {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 30px;
        margin-top: 0.5rem;
    }

    .schedule a {
        margin-right: 0.5rem;
        font-size: 14px;
    }

    .submit-btn {
        position: absolute;
        right: 20px;
        bottom: 80px;
    }

    .submit-btn button {
        padding: 16px 55px 18px 55px;
        background: #49BAF1;
        color: white;
        border: 1px solid #49BAF1;
        border-radius: 88px;
        font-size: 16px;
    }
}