.input-fields, textarea {
  height: 40px;
  color: #49baf1;
  background: #fff;
  border: 1px solid #0f3a5754;
  border-radius: 4px;
  padding-left: 1.5rem;
  font-size: 16px;
  font-weight: 400;
  box-shadow: inset 2px 2px 6px #0000001a;
}

.input-fields:focus, textarea:focus {
  border: 1px solid #49baf1;
  outline: none;
}

textarea {
  width: 100%;
  height: auto;
  padding-top: 1rem;
  padding-left: 1.5rem;
  resize: none !important;
}

#form {
  width: 100vw;
  z-index: 105;
  position: fixed;
  inset: 0;
  overflow-y: auto !important;
}

@media only screen and (max-width: 575px) {
  .form-submitted {
    height: 100vh;
    width: 100vw;
    justify-content: center;
    display: flex;
  }

  .form-submitted-content {
    flex-direction: column;
    align-items: center;
    margin-top: 14.5vw;
    padding: 0 6vw;
    display: flex;
  }

  .form-submitted-content h1 {
    color: #34790f;
    text-align: center;
    margin-top: 2rem;
    font-size: 24px;
  }

  .form-container {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 6vw;
    display: flex;
  }

  .close {
    cursor: pointer;
    width: 40px;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
  }

  .form-content {
    flex-direction: column;
    display: flex;
  }

  .form-separator {
    width: 140px;
    height: 1px;
    background: #0f3a574d;
    margin-top: 2.5rem;
    margin-bottom: .75rem;
  }

  .form-content h1 {
    color: #0f3a57;
    margin-top: 2rem;
    margin-bottom: 1.75rem;
    font-size: 36px;
  }

  .form-content h5 {
    color: #0f3a57b3;
    width: 100%;
    font-size: 19px;
  }

  .form-content p {
    color: #0f3a57b3;
    width: 70%;
    font-size: 13px;
  }

  .form-fields {
    width: 100%;
    flex-direction: column;
    margin-top: 3rem;
    display: flex;
  }

  .row1, .row2 {
    flex-direction: column;
    margin-bottom: 2rem;
    display: flex;
  }

  .row3 {
    flex-direction: column;
    margin-bottom: 2rem;
    padding-left: 2rem;
    display: flex;
    position: relative;
  }

  .row1-column1 {
    flex-direction: column;
    margin-bottom: 2rem;
    display: flex;
  }

  .row1-column2 {
    width: 100%;
    flex-direction: column;
    display: flex;
  }

  .row2-column1 {
    flex-direction: column;
    margin-bottom: 2rem;
    display: flex;
  }

  .row2-column2 {
    flex-direction: column;
    display: flex;
  }

  .full-name-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .full-name-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .full-name-field {
    width: 100%;
    padding-left: 30px;
  }

  .full-name-field input {
    width: 100%;
  }

  .phone-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .phone-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .phone-field {
    padding-left: 30px;
  }

  .phone-field input {
    width: 100%;
  }

  .email-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .email-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .email-field {
    width: 100%;
    padding-left: 30px;
  }

  .email-field input {
    width: 100%;
  }

  .check, .check img {
    width: 20px;
  }

  .input-fields {
    height: 40px;
    color: #49baf1;
    background: #fff;
    border: 1px solid #0f3a5754;
    border-radius: 4px;
    padding-left: 1.5rem;
    box-shadow: inset 2px 2px 6px #0000001a;
  }

  .input-fields:focus {
    border: 1px solid #49baf1;
    outline: none;
  }

  .calendly {
    width: 170px;
    padding-left: 30px;
  }

  .schedule {
    align-items: center;
    margin-top: .5rem;
    padding-left: 30px;
    display: flex;
  }

  .schedule a {
    margin-right: .5rem;
    font-size: 14px;
  }

  .submit-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .submit-btn button {
    color: #fff;
    background: #49baf1;
    border: 1px solid #49baf1;
    border-radius: 88px;
    padding: 16px 55px 18px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .form-submitted {
    height: 100vh;
    width: 100vw;
    justify-content: center;
    display: flex;
  }

  .form-submitted-content {
    flex-direction: column;
    align-items: center;
    margin-top: 14.5vw;
    padding: 0 6vw;
    display: flex;
  }

  .form-submitted-content h1 {
    color: #34790f;
    text-align: center;
    margin-top: 2rem;
    font-size: 36px;
  }

  .form-container {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 6vw;
    display: flex;
  }

  .close {
    cursor: pointer;
    width: 40px;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
  }

  .form-content {
    width: 492px;
    flex-direction: column;
    flex: 1;
    margin-right: 3vw;
    display: flex;
  }

  .form-separator {
    width: 140px;
    height: 1px;
    background: #0f3a574d;
    margin-top: 2.5rem;
    margin-bottom: .75rem;
  }

  .form-content h1 {
    color: #0f3a57;
    margin-top: 2rem;
    margin-bottom: 1.75rem;
    font-size: 36px;
  }

  .form-content h5 {
    color: #0f3a57b3;
    width: 86%;
    font-size: 19px;
  }

  .form-content p {
    color: #0f3a57b3;
    width: 70%;
    font-size: 13px;
  }

  .form-fields {
    width: 500px;
    flex-direction: column;
    margin-top: 3rem;
    display: flex;
  }

  .row1, .row2 {
    margin-bottom: 2rem;
    display: flex;
  }

  .row3 {
    margin-bottom: 2rem;
    padding-left: 2rem;
    display: flex;
    position: relative;
  }

  .row1-column1 {
    flex-direction: column;
    margin-right: .5rem;
    display: flex;
  }

  .row1-column2 {
    width: 100%;
    flex-direction: column;
    display: flex;
  }

  .row2-column1 {
    flex-direction: column;
    margin-right: .5rem;
    display: flex;
  }

  .row2-column2 {
    flex-direction: column;
    display: flex;
  }

  .full-name-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .full-name-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .full-name-field {
    width: 220px;
    padding-left: 30px;
  }

  .full-name-field input {
    width: 90%;
  }

  .phone-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .phone-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .phone-field {
    padding-left: 30px;
  }

  .phone-field input {
    width: 100%;
  }

  .email-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .email-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .email-field {
    width: 252px;
    padding-left: 30px;
  }

  .email-field input {
    width: 100%;
  }

  .check, .check img {
    width: 20px;
  }

  .input-fields {
    height: 40px;
    color: #49baf1;
    background: #fff;
    border: 1px solid #0f3a5754;
    border-radius: 4px;
    padding-left: 1.5rem;
    box-shadow: inset 2px 2px 6px #0000001a;
  }

  .input-fields:focus {
    border: 1px solid #49baf1;
    outline: none;
  }

  .calendly {
    width: 170px;
    padding-left: 30px;
  }

  .schedule {
    justify-content: center;
    align-items: center;
    margin-top: .5rem;
    padding-left: 30px;
    display: flex;
  }

  .schedule a {
    margin-right: .5rem;
    font-size: 14px;
  }

  .submit-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .submit-btn button {
    color: #fff;
    background: #49baf1;
    border: 1px solid #49baf1;
    border-radius: 88px;
    padding: 16px 55px 18px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .form-submitted {
    height: 100vh;
    width: 100vw;
    justify-content: center;
    display: flex;
  }

  .form-submitted-content {
    flex-direction: column;
    align-items: center;
    margin-top: 14.5vw;
    padding: 0 6vw;
    display: flex;
  }

  .form-submitted-content h1 {
    color: #34790f;
    text-align: center;
    margin-top: 2rem;
    font-size: 36px;
  }

  .form-container {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 6vw;
    display: flex;
  }

  .close {
    cursor: pointer;
    width: 40px;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
  }

  .form-content {
    width: 492px;
    flex-direction: column;
    flex: 1;
    margin-right: 3vw;
    display: flex;
  }

  .form-separator {
    width: 140px;
    height: 1px;
    background: #0f3a574d;
    margin-top: 2.5rem;
    margin-bottom: .75rem;
  }

  .form-content h1 {
    color: #0f3a57;
    margin-top: 2rem;
    margin-bottom: 1.75rem;
    font-size: 36px;
  }

  .form-content h5 {
    color: #0f3a57b3;
    width: 86%;
    font-size: 19px;
  }

  .form-content p {
    color: #0f3a57b3;
    width: 70%;
    font-size: 13px;
  }

  .form-fields {
    width: 600px;
    flex-direction: column;
    margin-top: 3rem;
    display: flex;
  }

  .row1, .row2 {
    margin-bottom: 2rem;
    display: flex;
  }

  .row3 {
    margin-bottom: 2rem;
    padding-bottom: 60px;
    padding-left: 2rem;
    display: flex;
    position: relative;
  }

  .row1-column1 {
    flex-direction: column;
    margin-right: .5rem;
    display: flex;
  }

  .row1-column2 {
    width: 100%;
    flex-direction: column;
    display: flex;
  }

  .row2-column1 {
    flex-direction: column;
    margin-right: .5rem;
    display: flex;
  }

  .row2-column2 {
    flex-direction: column;
    display: flex;
  }

  .full-name-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .full-name-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .full-name-field {
    width: 310px;
    padding-left: 30px;
  }

  .full-name-field input {
    width: 80%;
  }

  .phone-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .phone-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .phone-field {
    padding-left: 30px;
  }

  .phone-field input {
    width: 100%;
  }

  .email-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .email-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .email-field {
    width: 40vw;
    padding-left: 30px;
  }

  .email-field input {
    width: 100%;
  }

  .check, .check img {
    width: 20px;
  }

  .input-fields {
    height: 40px;
    color: #49baf1;
    background: #fff;
    border: 1px solid #0f3a5754;
    border-radius: 4px;
    padding-left: 1.5rem;
    box-shadow: inset 2px 2px 6px #0000001a;
  }

  .input-fields:focus {
    border: 1px solid #49baf1;
    outline: none;
  }

  .calendly {
    width: 170px;
    padding-left: 30px;
  }

  .schedule {
    justify-content: center;
    align-items: center;
    margin-top: .5rem;
    padding-left: 30px;
    display: flex;
  }

  .schedule a {
    margin-right: .5rem;
    font-size: 14px;
  }

  .submit-btn {
    position: absolute;
    bottom: 80px;
    right: 20px;
  }

  .submit-btn button {
    color: #fff;
    background: #49baf1;
    border: 1px solid #49baf1;
    border-radius: 88px;
    padding: 16px 55px 18px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .form-submitted {
    height: 100vh;
    width: 100vw;
    justify-content: center;
    display: flex;
  }

  .form-submitted-content {
    flex-direction: column;
    align-items: center;
    margin-top: 14.5vw;
    padding: 0 6vw;
    display: flex;
  }

  .form-submitted-content h1 {
    color: #34790f;
    text-align: center;
    margin-top: 2rem;
    font-size: 48px;
  }

  .form-container {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 6vw 14.5vw;
    display: flex;
  }

  .close {
    cursor: pointer;
    width: 40px;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
  }

  .form-content {
    width: 492px;
    flex-direction: column;
    flex: 1;
    margin-right: 3vw;
    display: flex;
  }

  .form-separator {
    width: 140px;
    height: 1px;
    background: #0f3a574d;
    margin-top: 2.5rem;
    margin-bottom: .75rem;
  }

  .form-content h1 {
    color: #0f3a57;
    margin-top: 2rem;
    margin-bottom: 1.75rem;
    font-size: 36px;
  }

  .form-content h5 {
    color: #0f3a57b3;
    width: 86%;
    font-size: 19px;
  }

  .form-content p {
    color: #0f3a57b3;
    width: 70%;
    font-size: 13px;
  }

  .form-fields {
    width: 700px;
    flex-direction: column;
    margin-top: 3rem;
    display: flex;
  }

  .row1, .row2 {
    margin-bottom: 2rem;
    display: flex;
  }

  .row3 {
    margin-bottom: 2rem;
    padding-bottom: 60px;
    padding-left: 2rem;
    display: flex;
    position: relative;
  }

  .row1-column1 {
    flex-direction: column;
    margin-right: .5rem;
    display: flex;
  }

  .row1-column2 {
    width: 100%;
    flex-direction: column;
    display: flex;
  }

  .row2-column1 {
    flex-direction: column;
    margin-right: .5rem;
    display: flex;
  }

  .row2-column2 {
    flex-direction: column;
    display: flex;
  }

  .full-name-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .full-name-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .full-name-field {
    width: 30vw;
    padding-left: 30px;
  }

  .full-name-field input {
    width: 80%;
  }

  .phone-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .phone-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .phone-field {
    padding-left: 30px;
  }

  .phone-field input {
    width: 100%;
  }

  .email-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .email-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .email-field {
    width: 30vw;
    padding-left: 30px;
  }

  .email-field input {
    width: 100%;
  }

  .check, .check img {
    width: 20px;
  }

  .input-fields {
    height: 40px;
    color: #49baf1;
    background: #fff;
    border: 1px solid #0f3a5754;
    border-radius: 4px;
    padding-left: 1.5rem;
    box-shadow: inset 2px 2px 6px #0000001a;
  }

  .input-fields:focus {
    border: 1px solid #49baf1;
    outline: none;
  }

  .calendly {
    width: 15vw;
    padding-left: 30px;
  }

  .schedule {
    justify-content: center;
    align-items: center;
    margin-top: .5rem;
    padding-left: 30px;
    display: flex;
  }

  .schedule a {
    margin-right: .5rem;
    font-size: 14px;
  }

  .submit-btn {
    position: absolute;
    bottom: 80px;
    right: 20px;
  }

  .submit-btn button {
    color: #fff;
    background: #49baf1;
    border: 1px solid #49baf1;
    border-radius: 88px;
    padding: 16px 55px 18px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .form-submitted {
    height: 100vh;
    width: 100vw;
    justify-content: center;
    display: flex;
  }

  .form-submitted-content {
    flex-direction: column;
    align-items: center;
    margin-top: 14.5vw;
    display: flex;
  }

  .form-submitted-content h1 {
    max-width: 50vw;
    color: #34790f;
    text-align: center;
    margin-top: 2rem;
    font-size: 52px;
  }

  .form-container {
    height: 100vh;
    width: 100vw;
    justify-content: center;
    padding: 6vw;
    display: flex;
  }

  .close {
    cursor: pointer;
    width: 40px;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }

  .form-content {
    width: 492px;
    flex-direction: column;
    flex: 1;
    margin-right: 3vw;
    display: flex;
  }

  .form-separator {
    width: 140px;
    height: 1px;
    background: #0f3a574d;
    margin-top: 2.5rem;
    margin-bottom: .75rem;
  }

  .form-content h1 {
    color: #0f3a57;
    margin-top: 2rem;
    margin-bottom: 1.75rem;
    font-size: 36px;
  }

  .form-content h5 {
    color: #0f3a57b3;
    width: 86%;
    font-size: 19px;
  }

  .form-content p {
    color: #0f3a57b3;
    width: 70%;
    font-size: 13px;
  }

  .form-fields {
    width: 700px;
    flex-direction: column;
    margin-top: 3rem;
    display: flex;
  }

  .row1, .row2 {
    margin-bottom: 2rem;
    display: flex;
  }

  .row3 {
    margin-bottom: 2rem;
    padding-bottom: 60px;
    padding-left: 2rem;
    display: flex;
    position: relative;
  }

  .row1-column1 {
    flex-direction: column;
    margin-right: .5rem;
    display: flex;
  }

  .row1-column2 {
    width: 100%;
    flex-direction: column;
    display: flex;
  }

  .row2-column1 {
    flex-direction: column;
    margin-right: .5rem;
    display: flex;
  }

  .row2-column2 {
    flex-direction: column;
    display: flex;
  }

  .full-name-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .full-name-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .full-name-field {
    width: 20vw;
    padding-left: 30px;
  }

  .full-name-field input {
    width: 80%;
  }

  .phone-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .phone-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .phone-field {
    padding-left: 30px;
  }

  .phone-field input {
    width: 100%;
  }

  .email-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .email-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .email-field {
    width: 20vw;
    padding-left: 30px;
  }

  .email-field input {
    width: 100%;
  }

  .check, .check img {
    width: 20px;
  }

  .input-fields {
    height: 40px;
    color: #49baf1;
    background: #fff;
    border: 1px solid #0f3a5754;
    border-radius: 4px;
    padding-left: 1.5rem;
    box-shadow: inset 2px 2px 6px #0000001a;
  }

  .input-fields:focus {
    border: 1px solid #49baf1;
    outline: none;
  }

  .calendly {
    width: 10vw;
    padding-left: 30px;
  }

  .schedule {
    justify-content: center;
    align-items: center;
    margin-top: .5rem;
    padding-left: 30px;
    display: flex;
  }

  .schedule a {
    margin-right: .5rem;
    font-size: 14px;
  }

  .submit-btn {
    position: absolute;
    bottom: 80px;
    right: 20px;
  }

  .submit-btn button {
    color: #fff;
    background: #49baf1;
    border: 1px solid #49baf1;
    border-radius: 88px;
    padding: 16px 55px 18px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1799px) {
  .form-submitted {
    height: 100vh;
    width: 100vw;
    justify-content: center;
    display: flex;
  }

  .form-submitted-content {
    flex-direction: column;
    align-items: center;
    margin-top: 14.5vw;
    display: flex;
  }

  .form-submitted-content h1 {
    max-width: 50vw;
    color: #34790f;
    text-align: center;
    margin-top: 2rem;
    font-size: 52px;
  }

  .form-container {
    height: 100vh;
    width: 100vw;
    justify-content: center;
    padding: 6vw;
    display: flex;
  }

  .close {
    cursor: pointer;
    width: 60px;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }

  .form-content {
    width: 492px;
    flex-direction: column;
    flex: 1;
    margin-right: 3vw;
    display: flex;
  }

  .form-separator {
    width: 140px;
    height: 1px;
    background: #0f3a574d;
    margin-top: 2.5rem;
    margin-bottom: .75rem;
  }

  .form-content h1 {
    color: #0f3a57;
    margin-top: 2rem;
    margin-bottom: 1.75rem;
    font-size: 48px;
  }

  .form-content h5 {
    color: #0f3a57b3;
    width: 86%;
    font-size: 19px;
  }

  .form-content p {
    color: #0f3a57b3;
    width: 70%;
    font-size: 13px;
  }

  .form-fields {
    width: 700px;
    flex-direction: column;
    margin-top: 3rem;
    display: flex;
  }

  .row1, .row2 {
    margin-bottom: 2rem;
    display: flex;
  }

  .row3 {
    margin-bottom: 2rem;
    padding-bottom: 60px;
    padding-left: 2rem;
    display: flex;
    position: relative;
  }

  .row1-column1 {
    flex-direction: column;
    margin-right: .5rem;
    display: flex;
  }

  .row1-column2 {
    width: 100%;
    flex-direction: column;
    display: flex;
  }

  .row2-column1 {
    flex-direction: column;
    margin-right: .5rem;
    display: flex;
  }

  .row2-column2 {
    flex-direction: column;
    display: flex;
  }

  .full-name-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .full-name-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .full-name-field {
    width: 20vw;
    padding-left: 30px;
  }

  .full-name-field input {
    width: 80%;
  }

  .phone-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .phone-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .phone-field {
    padding-left: 30px;
  }

  .phone-field input {
    width: 100%;
  }

  .email-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .email-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .email-field {
    width: 20vw;
    padding-left: 30px;
  }

  .email-field input {
    width: 100%;
  }

  .check, .check img {
    width: 20px;
  }

  .input-fields {
    height: 40px;
    color: #49baf1;
    background: #fff;
    border: 1px solid #0f3a5754;
    border-radius: 4px;
    padding-left: 1.5rem;
    box-shadow: inset 2px 2px 6px #0000001a;
  }

  .input-fields:focus {
    border: 1px solid #49baf1;
    outline: none;
  }

  .calendly {
    width: 10vw;
    padding-left: 30px;
  }

  .schedule {
    justify-content: center;
    align-items: center;
    margin-top: .5rem;
    padding-left: 30px;
    display: flex;
  }

  .schedule a {
    margin-right: .5rem;
    font-size: 14px;
  }

  .submit-btn {
    position: absolute;
    bottom: 80px;
    right: 20px;
  }

  .submit-btn button {
    color: #fff;
    background: #49baf1;
    border: 1px solid #49baf1;
    border-radius: 88px;
    padding: 16px 55px 18px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 1800px) and (max-width: 2399px) {
  .form-submitted {
    height: 100vh;
    width: 100vw;
    justify-content: center;
    display: flex;
  }

  .form-submitted-content {
    flex-direction: column;
    align-items: center;
    margin-top: 14.5vw;
    display: flex;
  }

  .form-submitted-content h1 {
    max-width: 50vw;
    color: #34790f;
    text-align: center;
    margin-top: 2rem;
    font-size: 52px;
  }

  .form-container {
    height: 100vh;
    width: 100vw;
    justify-content: center;
    padding: 6vw 14.5vw;
    display: flex;
  }

  .close {
    cursor: pointer;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
  }

  .form-content {
    width: 492px;
    flex-direction: column;
    flex: 1;
    margin-right: 3vw;
    display: flex;
  }

  .form-separator {
    width: 140px;
    height: 1px;
    background: #0f3a574d;
    margin-top: 2.5rem;
    margin-bottom: .75rem;
  }

  .form-content h1 {
    color: #0f3a57;
    margin-top: 2rem;
    margin-bottom: 1.75rem;
    font-size: 52px;
  }

  .form-content h5 {
    color: #0f3a57b3;
    width: 74%;
    font-size: 19px;
  }

  .form-content p {
    color: #0f3a57b3;
    width: 58%;
    font-size: 13px;
  }

  .form-fields {
    width: 700px;
    flex-direction: column;
    margin-top: 3rem;
    display: flex;
  }

  .row1, .row2 {
    margin-bottom: 2rem;
    display: flex;
  }

  .row3 {
    margin-bottom: 2rem;
    padding-bottom: 60px;
    padding-left: 2rem;
    display: flex;
    position: relative;
  }

  .row1-column1 {
    flex-direction: column;
    margin-right: .5rem;
    display: flex;
  }

  .row1-column2 {
    width: 100%;
    flex-direction: column;
    display: flex;
  }

  .row2-column1 {
    flex-direction: column;
    margin-right: .5rem;
    display: flex;
  }

  .row2-column2 {
    flex-direction: column;
    display: flex;
  }

  .full-name-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .full-name-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .full-name-field {
    width: 20vw;
    padding-left: 30px;
  }

  .full-name-field input {
    width: 80%;
  }

  .phone-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .phone-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .phone-field {
    padding-left: 30px;
  }

  .phone-field input {
    width: 100%;
  }

  .email-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .email-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .email-field {
    width: 20vw;
    padding-left: 30px;
  }

  .email-field input {
    width: 100%;
  }

  .check, .check img {
    width: 20px;
  }

  .input-fields {
    height: 40px;
    color: #49baf1;
    background: #fff;
    border: 1px solid #0f3a5754;
    border-radius: 4px;
    padding-left: 1.5rem;
    box-shadow: inset 2px 2px 6px #0000001a;
  }

  .input-fields:focus {
    border: 1px solid #49baf1;
    outline: none;
  }

  .calendly {
    width: 10vw;
    padding-left: 30px;
  }

  .schedule {
    justify-content: center;
    align-items: center;
    margin-top: .5rem;
    padding-left: 30px;
    display: flex;
  }

  .schedule a {
    margin-right: .5rem;
    font-size: 14px;
  }

  .submit-btn {
    position: absolute;
    bottom: 80px;
    right: 20px;
  }

  .submit-btn button {
    color: #fff;
    background: #49baf1;
    border: 1px solid #49baf1;
    border-radius: 88px;
    padding: 16px 55px 18px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 2400px) {
  .form-submitted {
    height: 100vh;
    width: 100vw;
    justify-content: center;
    display: flex;
  }

  .form-submitted-content {
    flex-direction: column;
    align-items: center;
    margin-top: 14.5vw;
    display: flex;
  }

  .form-submitted-content h1 {
    max-width: 50vw;
    color: #34790f;
    text-align: center;
    margin-top: 2rem;
    font-size: 52px;
  }

  .form-container {
    height: 100vh;
    width: 100vw;
    justify-content: center;
    padding: 6vw 14.5vw;
    display: flex;
  }

  .close {
    cursor: pointer;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
  }

  .form-content {
    width: 492px;
    flex-direction: column;
    flex: 1;
    margin-right: 3vw;
    display: flex;
  }

  .form-separator {
    width: 140px;
    height: 1px;
    background: #0f3a574d;
    margin-top: 2.5rem;
    margin-bottom: .75rem;
  }

  .form-content h1 {
    color: #0f3a57;
    margin-top: 2rem;
    margin-bottom: 1.75rem;
    font-size: 52px;
  }

  .form-content h5 {
    color: #0f3a57b3;
    width: 74%;
    font-size: 19px;
  }

  .form-content p {
    color: #0f3a57b3;
    width: 58%;
    font-size: 13px;
  }

  .form-fields {
    width: 700px;
    flex-direction: column;
    margin-top: 3rem;
    display: flex;
  }

  .row1, .row2 {
    margin-bottom: 2rem;
    display: flex;
  }

  .row3 {
    margin-bottom: 2rem;
    padding-bottom: 60px;
    padding-left: 2rem;
    display: flex;
    position: relative;
  }

  .row1-column1 {
    flex-direction: column;
    margin-right: .5rem;
    display: flex;
  }

  .row1-column2 {
    width: 100%;
    flex-direction: column;
    display: flex;
  }

  .row2-column1 {
    flex-direction: column;
    margin-right: .5rem;
    display: flex;
  }

  .row2-column2 {
    flex-direction: column;
    display: flex;
  }

  .full-name-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .full-name-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .full-name-field {
    width: 20vw;
    padding-left: 30px;
  }

  .full-name-field input {
    width: 80%;
  }

  .phone-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .phone-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .phone-field {
    padding-left: 30px;
  }

  .phone-field input {
    width: 100%;
  }

  .email-text {
    align-items: center;
    margin-bottom: 14px;
    display: flex;
  }

  .email-text p {
    color: #143a57;
    margin: 0;
    padding-left: 10px;
  }

  .email-field {
    width: 20vw;
    padding-left: 30px;
  }

  .email-field input {
    width: 100%;
  }

  .check, .check img {
    width: 20px;
  }

  .input-fields {
    height: 40px;
    color: #49baf1;
    background: #fff;
    border: 1px solid #0f3a5754;
    border-radius: 4px;
    padding-left: 1.5rem;
    box-shadow: inset 2px 2px 6px #0000001a;
  }

  .input-fields:focus {
    border: 1px solid #49baf1;
    outline: none;
  }

  .calendly {
    width: 10vw;
    padding-left: 30px;
  }

  .schedule {
    justify-content: center;
    align-items: center;
    margin-top: .5rem;
    padding-left: 30px;
    display: flex;
  }

  .schedule a {
    margin-right: .5rem;
    font-size: 14px;
  }

  .submit-btn {
    position: absolute;
    bottom: 80px;
    right: 20px;
  }

  .submit-btn button {
    color: #fff;
    background: #49baf1;
    border: 1px solid #49baf1;
    border-radius: 88px;
    padding: 16px 55px 18px;
    font-size: 16px;
  }
}

/*# sourceMappingURL=index.1cf48fd7.css.map */
